@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-dark text-white font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.prose {
  --tw-prose-body: #e5e7eb;
  --tw-prose-headings: #99abd6;
  --tw-prose-lead: #e5e7eb;
  --tw-prose-links: #99abd6;
  --tw-prose-bold: #e5e7eb;
  --tw-prose-counters: #e5e7eb;
  --tw-prose-bullets: #e5e7eb;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #e5e7eb;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #e5e7eb;
  --tw-prose-code: #e5e7eb;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #e5e7eb;
  --tw-prose-td-borders: #e5e7eb;
}

.prose-invert {
  max-width: 100%;
  --tw-prose-body: #e5e7eb;
  --tw-prose-headings: #99abd6;
  --tw-prose-lead: #e5e7eb;
  --tw-prose-links: #99abd6;
  --tw-prose-bold: #e5e7eb;
  --tw-prose-counters: #e5e7eb;
  --tw-prose-bullets: #e5e7eb;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #e5e7eb;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #e5e7eb;
  --tw-prose-code: #e5e7eb;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #e5e7eb;
  --tw-prose-td-borders: #e5e7eb;
}

.text-audiowide {
  font-family: 'Audiowide', cursive;
}

.text-roboto {
  font-family: 'Roboto Condensed', sans-serif;
}

.text-secular {
  font-family: 'Secular One', sans-serif;
}

a:hover {
  color: #fff;
}

.prose img {
  @apply rounded-lg;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes pulse {
  0% {
      transform: scale(1);
      opacity: 0.9;
  }
  50% {
      transform: scale(1.1);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0.9;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes colorCycle {
  0% {
    color: #fff; /* Red */
  }
  25% {
    color: #99abd6; /* Green */
  }
  50% {
    color: #4e4e4e; /* Blue */
  }
  75% {
    color: #99abd6; /* Yellow */
  }
  100% {
    color: #fff; /* Red */
  }
}

.color-transition {
  animation: colorCycle 4s infinite;
  animation-timing-function: ease-in-out;
}
